<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas del Almacen de Equipos</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL ALMANCEN DE EQUIPOS:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-tablet fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listEquipos.length}}</span>
                                <br>
                                <span class="text-muted">Equipos registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestion del Almacen de Equipos</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <!-- <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name:'Proyectos'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col> -->
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" @click="modalRegistrarEquipos = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro</span>
                                </b-button>
                            </b-col>
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name:'Check List Equipos'}">
                                    <i style="inline-size: auto" class="fas fa-clipboard-list fa-3x pb-1"></i><br><span>Check List Equipos</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>

            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista del Almacen de Equipos Registrados </span>
                        <!-- <b-button class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                            <i class="fas fa-download mr-1"></i>
                            Exportar</b-button> -->
                        <b-button v-if="listEquipos.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listEquipos" :fields="camposRegistroEquipos" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(codigo)="data">
                                        {{ data.item.codigo }}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{ data.item.nombre | truncate(50) }}
                                    </template>
                                    <template v-slot:cell(categoria)="data">
                                        {{listCategoria.find(x => x.id == data.item.idCategoria).categorias }}
                                    </template>
                                    <template v-slot:cell(cantidad)="data">
                                        {{ data.item.cantidad }}
                                    </template>
                                    <template v-slot:cell(estado)="data">
                                        {{listTipEstado.find(x => x.id == data.item.idTipoEstado).nombre}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarEquipo(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarEquipo(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalRegistrarEquipos" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Registro de Equipos</span>
                </h6>
                <CButtonClose @click="modalRegistrarEquipos = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarEquipos)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="codigo" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Codigo:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el codigo" @input="upper($event,1)" v-model.trim="datosEquipo.codigo"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model.trim="datosEquipo.nombre"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo categoria" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Categoria:" class="mb-2">
                                    <v-select :reduce="listCategoria => listCategoria.id" label="categorias" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEquipo.idcategoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosEquipo.idcategoria" :options="listCategoria" @search:blur="blurRegTipoCategoria">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="cantidad total" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                                <b-form-group label="Cantidad Total:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad" v-model.trim="datosEquipo.cantidad"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo estado" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Estado:" class="mb-2">
                                    <v-select :reduce="listTipEstado => listTipEstado.id" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEquipo.idtipoEstado , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosEquipo.idtipoEstado" :options="listTipEstado" @search:blur="blurRegTipoEstado">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observación" v-slot="validationContext">
                                <b-form-group label="Observación (Opcional):" class="mb-2">
                                    <b-form-textarea v-model="datosEquipo.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegistrarEquipos = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActualizarEquipos" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Actualizar de Equipos</span>
                </h6>
                <CButtonClose @click="modalActualizarEquipos = false" class="text-white" />
            </template>
            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarEquipos)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="codigo" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Codigo:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el codigo" @input="upper($event,2)" v-model.trim="datosActualizarEquipo.codigo"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model.trim="datosActualizarEquipo.nombre"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo categoria" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Categoria:" class="mb-2">
                                    <v-select :reduce="listCategoria => listCategoria.id" label="categorias" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarEquipo.idcategoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActualizarEquipo.idcategoria" :options="listCategoria" @search:blur="blurActTipoCategoria">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="cantidad total" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                                <b-form-group label="Cantidad Total:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad" v-model.trim="datosActualizarEquipo.cantidad"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo estado" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Estado:" class="mb-2">
                                    <v-select :reduce="listTipEstado => listTipEstado.id" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarEquipo.idtipoEstado , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActualizarEquipo.idtipoEstado" :options="listTipEstado" @search:blur="blurActTipoEstado">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observación" v-slot="validationContext">
                                <b-form-group label="Observación (Opcional):" class="mb-2">
                                    <b-form-textarea v-model="datosActualizarEquipo.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button variant="danger" @click="modalActualizarEquipos = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>


<script>
import firebase from 'firebase';
const dbEquipos = firebase.firestore().collection('checkListEquipos');
const dbCategoria = firebase.firestore().collection('tipoCategoria');
const dbTipEstado = firebase.firestore().collection('tipoEstado');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            disabled: false,
            listEquipos: [],
            listCategoria: [],
            listTipEstado: [],

            camposRegistroEquipos: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Codigo",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "categoria",
                    label: "Tipo Categoria",
                    class: "text-center",
                },
                {
                    key: "cantidad",
                    label: "Cantidad Total",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Tipo Estado",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            datosEquipo: {
                codigo: '',
                nombre: '',
                idcategoria: '',
                cantidad: '',
                idtipoEstado: '',
                observacion: '',
            },

            datosActualizarEquipo: {
                idEquipos: '',
                codigo: '',
                nombre: '',
                idcategoria: '',
                cantidad: '',
                idtipoEstado: '',
                observacion: '',
            },

            filter: null,
            filterOn: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            modalRegistrarEquipos: false,
            modalActualizarEquipos: false,
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    let dateValidation = moment(date, 'DD/MM/YYYY', true);
                    if (dateValidation.isValid()) {
                        return dateValidation
                    } else {
                        return date
                    }
                }
                return '';
            };

            let dataExcel = this.listEquipos.map(data => {
                return {
                    A: valUpper(data['codigo']),
                    B: valUpper(data['nombre']),
                    C: valUpper(this.listCategoria && this.listCategoria !== undefined ?
                        this.listCategoria.filter(x => x.id == data.idCategoria).length > 0 ?
                        this.listCategoria.filter(x => x.id == data.idCategoria)[0].categorias : '' : ''),
                    D: valUpper(data['cantidad']),
                    E: valUpper(this.listTipEstado && this.listTipEstado !== undefined ?
                        this.listTipEstado.filter(x => x.id == data.idTipoEstado).length > 0 ?
                        this.listTipEstado.filter(x => x.id == data.idTipoEstado)[0].nombre : '' : ''),
                    F: valUpper(data['observacion']),
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [
                {
                    s: {
                        r: 1,
                        c: 1,
                    },
                    e: {
                        r: 1,
                        c: 3,
                    },
                }
            ];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 15, //A
                },
                {
                    wch: 30, //B
                },
                {
                    wch: 25, //C
                },
                {
                    wch: 15, //D
                },
                {
                    wch: 30, //E
                },
                {
                    wch: 40, //F
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for (let i of [
                {
                    f: 'B2',
                    v: 'ALMANCEN DE EQUIPOS',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'CODIGO',
                },
                {
                    f: 'B4',
                    v: 'NOMBRE EQUIPO',
                },
                {
                    f: 'C4',
                    v: 'CATEGORIA',
                },
                {
                    f: 'D4',
                    v: 'CANTIDAD TOTAL',
                },
                {
                    f: 'E4',
                    v: 'ESTADO',
                },
                {
                    f: 'F4',
                    v: 'OBSERVACIÓN',
                },
            ]) {
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'ALMANCEN DE EQUIPOS');
            XLSX.writeFile(wb, `ALMANCEN DE EQUIPOS ${now}.xlsx`);
        },
        upper(e,index) {
            if(index == 1){
                this.datosEquipo.codigo = e.toUpperCase()
            }else{
                this.datosActualizarEquipo.codigo = e.toUpperCase()
            }
            
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurRegTipoCategoria() {
            this.computedRegForms.refs['tipo categoria'].validate();
        },
        blurActTipoCategoria() {
            this.computedActForms.refs['tipo categoria'].validate();
        },
        blurRegTipoEstado() {
            this.computedRegForms.refs['tipo estado'].validate();
        },
        blurActTipoEstado() {
            this.computedActForms.refs['tipo estado'].validate();
        },
        registrarEquipos() {
            let me = this;
            me.disabled = true;

            dbEquipos.add({
                codigo: me.datosEquipo.codigo.trim(),
                nombre: me.datosEquipo.nombre.trim(),
                idCategoria: me.datosEquipo.idcategoria.trim(),
                cantidad: me.datosEquipo.cantidad.trim(),
                idTipoEstado: me.datosEquipo.idtipoEstado.trim(),
                observacion: me.datosEquipo.observacion.trim(),
                estado: 2,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                idCliente: me.$store.state.user.idCliente,
            })
            .then((doc) => {
                me.swat('success', 'Registrado satisfactoriamente')
                me.modalRegistrarEquipos = false
                me.disabled = false;
            })
            .catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });
        },
        listarEquipos() {
            let me = this;
            
            dbEquipos
            .where("estado","==", 2)
            .where("idCliente","==",String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listEquipos = [];
                querySnapshot.forEach((doc) => {
                    me.listEquipos.push({
                        idEquipos: doc.id,
                        codigo: doc.data().codigo,
                        nombre: doc.data().nombre,
                        idCategoria: doc.data().idCategoria,
                        cantidad: doc.data().cantidad,
                        idTipoEstado: doc.data().idTipoEstado,
                        observacion: doc.data().observacion,
                        estado: doc.data().estado,
                        fechaCreacion: doc.data().fechaCreacion,
                    });
                });
                
                me.listEquipos.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        listarCategoria() {
            let me = this;

            dbCategoria
            .where("estado","==", 2)
            .onSnapshot((querySnapshot) => {
                me.listCategoria = [];
                querySnapshot.forEach((doc) => {
                    me.listCategoria.push({
                        id: doc.id,
                        categorias: doc.data().categoria,
                    });
                });
                
            });
        },
        listarEstado() {
            let me = this;

            dbTipEstado
            .where("estado","==", 2)
            .onSnapshot((querySnapshot) => {
                me.listTipEstado = [];
                querySnapshot.forEach((doc) => {
                    me.listTipEstado.push({
                        id: doc.id,
                        nombre: doc.data().nombre,
                    });
                });
                
            });
        },
        eliminarEquipo(param) {
            let me = this;
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el Equipo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbEquipos.doc(param.item.idEquipos).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then((doc) => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disabled = false;
                    })
                    .catch((error) => {
                        me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            })
        },
        actualizarEquipos(){
            let me = this;
            me.disabled = true;

            dbEquipos
            .doc(me.datosActualizarEquipo.idEquipos)
            .update({
                codigo: me.datosActualizarEquipo.codigo.trim(),
                nombre: me.datosActualizarEquipo.nombre.trim(),
                idCategoria: me.datosActualizarEquipo.idcategoria.trim(),
                cantidad: me.datosActualizarEquipo.cantidad.trim(),
                idTipoEstado: me.datosActualizarEquipo.idtipoEstado.trim(),
                observacion: me.datosActualizarEquipo.observacion.trim(),
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            .then(() => {
                me.swat('success', 'Modificado satisfactoriamente')
                me.modalActualizarEquipos = false
                me.disabled = false;
            })
            .catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });;
        },
        abrirModalActualizarEquipo(param){
            let me = this;

            me.datosActualizarEquipo.idEquipos = param.item.idEquipos;
            me.datosActualizarEquipo.codigo = param.item.codigo.trim();
            me.datosActualizarEquipo.nombre = param.item.nombre.trim();
            me.datosActualizarEquipo.idcategoria = param.item.idCategoria;
            me.datosActualizarEquipo.cantidad = param.item.cantidad.trim();
            me.datosActualizarEquipo.idtipoEstado = param.item.idTipoEstado;
            me.datosActualizarEquipo.observacion = param.item.observacion.trim();
            me.modalActualizarEquipos = true;
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarEquipos() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosEquipo.codigo = '';
            this.datosEquipo.nombre = '';
            this.datosEquipo.idcategoria = '';
            this.datosEquipo.cantidad = '';
            this.datosEquipo.idtipoEstado = '';
            this.datosEquipo.observacion = '';
        },
        resetModalActualizarEquipos() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

            this.datosActualizarEquipo.idEquipos = '';
            this.datosActualizarEquipo.codigo = '';
            this.datosActualizarEquipo.nombre = '';
            this.datosActualizarEquipo.idcategoria   = '';
            this.datosActualizarEquipo.cantidad = '';
            this.datosActualizarEquipo.idtipoEstado = '';
            this.datosActualizarEquipo.observacion = '';
        },
    },
    computed: {
        computedRegForms() {
            return this.$refs.observer;
        },
        computedActForms() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalRegistrarEquipos: function (val) {
            if (val == false) {
                this.resetModalRegistrarEquipos();
            }
        },
        modalActualizarEquipos: function (val) {
            if (val == false) {
                this.resetModalActualizarEquipos();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            this.listarCategoria();
            this.listarEstado();
            this.listarEquipos();
        }
    },
}
</script>